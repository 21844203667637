import timg1 from '../images/team/team_member_image_1.png'




const Teams = [

   {
      Id: '1',
      tImg: timg1,
      name: 'Muhammad Umair',
      slug: 'umair-asif',
      title: 'IT Manager',
   },

]

export default Teams;